import './App.css';
import { createBrowserHistory, ReactLocation, Router } from 'react-location';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { routes } from './router/routes';

const history = createBrowserHistory()
const location = new ReactLocation({ history })

const colors = {
  brand: {
    900: '#1C495D',
    800: '#153F75',
    700: '#00AEEF',
  },
}

const _theme = extendTheme(colors);

function App() {
  return (
    <ChakraProvider theme={_theme}>
      <Router routes={routes} location={location} />
    </ChakraProvider>
  );
}

export default App;
