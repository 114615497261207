import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { useLocation } from "react-location";
import NavigationDrawer from "./NavigationDrawer";

export interface HomeNav {
  text: string;
  path: string;
}

export const homeNavs: HomeNav[] = [
  {
    text: "Home",
    path: "/",
  },
  {
    text: "About Us",
    path: "/about",
  },
  {
    text: "Services",
    path: "/services",
  },
  {
    text: "Projects",
    path: "/projects",
  },
  {
    text: "Our Team",
    path: "/team",
  },
  {
    text: "Contact Us",
    path: "/contact-us",
  },
];

function NavigationBar() {
  const sidebar = useDisclosure();
  const location = useLocation();

  return (
    <Container
      maxW="container.xl"
      alignContent="center"
      pt={{ base: "5", md: "0" }}
    >
      <Flex
        direction="row"
        py="3"
        mb="10"
        display={{ base: "none", md: "flex" }}
        justifyContent="center"
      >
        <Box mx={4} as="a" href="/">
          <Image src="images/base-images/logo.svg" w={75} />
        </Box>
        <Flex ml={10} direction="row" alignItems="center">
          {homeNavs.map((nav: HomeNav, i) => (
            <Box key={i} mx={4} as="a" href={nav.path}>
              <Text
                color={
                  nav.path === location.current.pathname ? "blue.300" : "black"
                }
                _hover={{ color: "blue.500" }}
              >
                {nav.text}
              </Text>
            </Box>
          ))}
        </Flex>
      </Flex>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        display={{ base: "flex", md: "none" }}
      >
        <Box mx={4} as="a" href="/">
          <Image src="images/base-images/logo.svg" w={75} />
        </Box>
        <HiOutlineMenuAlt1 onClick={sidebar.onToggle} size="40" />
      </Flex>
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <NavigationDrawer homeNavs={homeNavs} onClose={sidebar.onClose} />
        </DrawerContent>
      </Drawer>
    </Container>
  );
}

export default NavigationBar;
