import {
  Container,
  GridItem,
  Image,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import PageTitle from "../../assets/PageTitle";
import MainLayout from "../../layouts/MainLayout";
import ServiceSection from "./components/ServiceSection";

function ServicePage() {
  return (
    <MainLayout>
      <Container maxW="container.xl">
        <PageTitle title="Services" />
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, md: 2 }}
          columnGap={5}
          rowGap={{base: "80px", md: "150px"}}
        >
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <VStack gap="150px">
              <ServiceSection
                title="Working with Kags & Co."
                body="We can manage your project from initiation to completion. Or if your active project is missing milestones or is lacking momentum, contact us and we will deploy project management consultants to get your project back on track."
                titleWeight="normal"
              />
              <Image
                src="images/service-images/value.jpg"
                objectFit="fill"
                borderRadius={15}
              />
            </VStack>
          </GridItem>
          <ServiceSection
            title="Capital Project Management"
            body="We oversee capital improvements throughout their duration, mitigating risks while maintaining tight control over scope, schedule, and budget. From developing the business case, to the project management of the design, planning, and execution, we make sure the project delivers the expected value."
          />
          <ServiceSection
            title="Strategy Realization"
            body="We translate strategy into action by combining our time-tested expertise in strategic planning, portfolio management, project execution, and organizational engagement to provide a holistic approach to transforming your organization or investment."
          />
          <ServiceSection
            title="Project Portfolio Management"
            body="We assist organizations in doing the right projects—those with strategic alignment that make the best use of your time, talent, and budget—and doing them right."
          />
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <ServiceSection
              title="Product Development & Implementation"
              body="We perform this through:"
              bullets={[
                "Project framing and definition",
                "Development of detailed project plans",
                "Establishment of a project team",
                "Managing the professional team, consultants, suppliers, and contractors",
                "Management of the procurement process and drafting of contracts",
                "Project scheduling and time management",
                "Cost control",
                "Project meetings and reports",
                "Client liaising",
                "Overall scope control and deviation management",
                "Project commissioning and handover",
                "Project close-out.",
                "Establishing functional roles and responsibilities with respect to the client and contractor organizations",
              ]}
            />
          </GridItem>
        </SimpleGrid>
      </Container>
    </MainLayout>
  );
}

export default ServicePage;
