import { HomePageData } from "../../layouts/interfaces/HomePage/HomePageData.interface";
import MainLayout from "../../layouts/MainLayout";
import HomeDisplayItem from "./components/HomeDisplayItem";

function HomePage() {
  const homePageData: HomePageData[] = [
    {
      title: "What We Are About",
      body: "Kags & Co.’s core value proposition is to assist its clients with identifying, planning and implementing operational performance improvement initiatives, in order to achieve operational performance excellence.",
      link: " Learn more about us",
      href: "/about",
      image: "images/home-images/home-about.png",
    },
    {
      title: "What We Offer",
      body: "The utilization of accumulated expertise, know-how and the dynamics of its partnerships, will allow the company to positively respond to the challenges of our times and provide expert, state of the art solutions that lead straight to the point of its customer needs.",
      link: "Browse our services",
      href: "/services",
      image: "images/home-images/home-services.png",
    },
    {
      title: "Meet The Team",
      body: "All the professional executives of Kags & Co. have at least a university degree and have completed enough post-university studies and have a Doctorate.",
      link: "Find an expert that suits you",
      href: "/team",
      image: "images/home-images/home-team.png",
    },
  ];
  return (
    <MainLayout>
        {homePageData.map((data, i) => (
          <HomeDisplayItem data={data} reverse={i % 2 !== 0 ? true : false} />
        ))}
    </MainLayout>
  );
}

export default HomePage;
