import { Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react'

interface Props {
    title: string;
    subtitle?: string;
}

function PageTitle(props: Props) {

  return (
    <VStack gap={5} alignItems="start" my={20}>
      <Heading as="h1" size="4xl" color="#1C4A5D">
        {props.title}
    </Heading>
    {props.subtitle && <Text>{props.subtitle}</Text>}
    </VStack>
  )
}

export default PageTitle