import { Image, Stack } from "@chakra-ui/react";

interface Props {
  images: string[];
  title: string;
}

function ImageSlider(props: Props) {
  const { images, title } = props;

  return (
    <Stack direction={{base: "row", md: "column"}} overflowX={{base: "scroll", md: "auto"}} alignItems="center">
      {images.map((image, i) => (
        <Image
        key={i}
          src={image}
          alt={`${title} image ${i + 1}`}
          borderTopRadius={{base: 0, md: i === 0 ? 15 : 0}}
          borderBottomRadius={{base: 0, md: i === images.length - 1 ? 15 : 0}}
          width={{base: "full", md: "60%"}}
        />
      ))}
    </Stack>
  );
}

export default ImageSlider;
