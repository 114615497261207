import { Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { MemberInfo } from "../../../assets/TeamInformation";

interface Props {
  member: MemberInfo;
}

function MemberItem(props: Props) {
  const { member } = props;

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      gap={10}
      p={3}
      borderRadius={15}
      bgColor="#F7F7F756"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        w={{ base: "full", md: "40%" }}
      >
        <VStack gap={5}>
          <Image
            src={member.image}
            alt={member.name + " image"}
            borderRadius={"full"}
            w="50%"
          />
          <VStack gap={1} textAlign="center">
            <Heading
              as="h1"
              size="xl"
              fontWeight="light"
            >
              {member.name}
            </Heading>
            <Text mb={4} fontWeight="semibold" color="#00AEEF" fontSize="3xl">
              {member.title}
            </Text>
          </VStack>
        </VStack>
      </Flex>
      <Flex
        direction="column"
        justifyContent="center"
        w={{ base: "full", md: "60%" }}
      >
        {member.bio.map((par, i) => (
          <Text key={i} mb={3}>{par}</Text>
        ))}
      </Flex>
    </Flex>
  );

}

export default MemberItem;
