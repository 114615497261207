import {
  Box,
  Flex,
  GridItem,
  Heading,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BsPatchCheckFill } from "react-icons/bs";
import ImageSlider from "./ImageSlider";

interface ProjectProps {
  title: string;
  body: string;
  bullets?: string[];
  images?: string[];
}

interface Props {
  title: string;
  projects: ProjectProps[];
}

function ProjectDetailsSection(props: Props) {
  const { title, projects } = props;
  
  return (
    <Box p={5}
    borderRadius={15}
    bgColor="#F7F7F756">
      <Heading mb={10} as="h1" color="#1C495D" fontWeight="medium" size="2xl">
        {title}
      </Heading>
      <VStack gap="50px">
        {projects.map((project, i) => (
          <SimpleGrid key={i} columns={{ base: 1, md: project.images ? 2 : 1 }} w="full" gap={5}>
            <GridItem colSpan={1}>
              <VStack alignItems="start" w="full" gap={3}>
                <Heading as="h1" size="xl" fontWeight="normal">
                  {project.title}
                </Heading>
                <Text>{project.body}</Text>
                {project.bullets && (
                  <List>
                    <SimpleGrid
                      alignItems="start"
                      columns={1}
                      rowGap={3}
                    >
                      {project.bullets.map((bullet, i) => (
                        <ListItem key={i}>
                          <ListIcon as={BsPatchCheckFill} color="#1C495D" />
                          {bullet}
                        </ListItem>
                      ))}
                    </SimpleGrid>
                  </List>
                )}
              </VStack>
            </GridItem>
            {project.images && (
              <Flex alignItems="center" h="full">
                <ImageSlider images={project.images} title={project.title} />
              </Flex>
            )}
          </SimpleGrid>
        ))}
      </VStack>
    </Box>
  );
}

export default ProjectDetailsSection;
