import {
  Box,
  Circle,
  Container,
  Divider,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { GiRotaryPhone } from "react-icons/gi";
import { HomeNav, homeNavs } from "./NavigationBar";
import { useLocation } from "react-location";
import ContactBar from "./ContactBar";

function PageFooter() {
  const location = useLocation();
  return (
    <Box bgColor="#1C4A5DF8">
      <Container pt="10" pb="30" maxW="container.xl" color="white">
        {location.current.pathname !== "/contact-us" && (
          <ContactBar />
        )}

        <Flex my="10" justifyContent={{base: "center", md: "space-between"}} w="full">
          <Image
            src="images/base-images/logo-alt.svg"
            w={150}
            display={{ base: "none", md: "block" }}
          />
          <SimpleGrid columns={{ base: 2, md: 2 }} spacing={8}>
            {homeNavs.map((nav: HomeNav, i) => (
              <Box key={i} mx={4} as="a" href={nav.path}>
                <Text>{nav.text}</Text>
              </Box>
            ))}
          </SimpleGrid>
        </Flex>
        <Divider mb="5" />
        <Flex
          direction={{ base: "column-reverse", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack>
            <Image src="images/base-images/logo-alt.svg"w={12} display={{base: "block", md:"none"}} />
          <Text align={"center"}>@2021 Kags & Co Consultancy</Text>
          </HStack>
          <HStack
            direction="row"
            justifyContent="space-around"
            spacing={5}
            mb={{ base: "5", md: "0" }}
          >
            <Circle
              as="a"
              href="tel:+255745142047"
              size={10}
              bgColor="white"
              color="#1C4A5D"
              _hover={{ bgColor: "#FFFFFF80" }}
            >
              <FaPhoneAlt size="25" />
            </Circle>
            <Circle
              as="a"
              href="tel:+255759330091"
              size={10}
              bgColor="white"
              color="#1C4A5D"
              _hover={{ bgColor: "#FFFFFF80" }}
            >
              <GiRotaryPhone size="25" />
            </Circle>
            <Circle
              as="a"
              href="mailto:info@kags.co.tz"
              size={10}
              bgColor="white"
              color="#1C4A5D"
              _hover={{ bgColor: "#FFFFFF80" }}
            >
              <IoMdMail size="25" />
            </Circle>
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
}

export default PageFooter;
