import { Box, Heading, List, ListIcon, ListItem, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { ImCircleRight } from 'react-icons/im';

interface Props {
    title: string;
    paragraphs?: string[];
    bullets?: string[]
}

function AboutSection(props: Props) {
    const {title, paragraphs, bullets} = props;

  return (
    <Box>
        <Heading fontWeight="normal" color="#00AEEF" mb="5">{title}</Heading>
        {paragraphs && <VStack alignItems="start" mb={bullets && 3}>{paragraphs.map((paragraph, i) => (<Text key={i}>{paragraph}</Text>))}</VStack>}
        {bullets && <List>
            <SimpleGrid
          alignItems="start"
          columns={{ base: 1, md: bullets.length > 8 ? 2 : 1 }}
          rowGap={3}
        >
          {bullets.map((bullet, i) => (
            <ListItem key={i}>
              <ListIcon as={ImCircleRight} />
              {bullet}
            </ListItem>
          ))}
        </SimpleGrid>
        </List>}
          
    </Box>
  )
}

export default AboutSection