import {
  Container,
  GridItem,
  SimpleGrid,
  VStack,
  Image
} from "@chakra-ui/react";
import PageTitle from "../../assets/PageTitle";
import MainLayout from "../../layouts/MainLayout";
import AboutSection from "./components/AboutSection";

function AboutPage() {
  return (
    <MainLayout>
      <Container maxW="container.xl">
      <PageTitle title="About Us" />
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, md: 2 }}
          columnGap={5}
          rowGap="100px"
        >
          {/* <Image
            src="images/about-images/abt-us.jpg"
            w="full"
            borderRadius={15}
          /> */}
          {/* <GridItem colSpan={{ base: 1, md: 2 }}> */}
  
            <AboutSection
              title="Who are We?"
              paragraphs={[
                "Since its establishment in 2020 Kags & Co. consulting has developed into a specialized and respected provider of project management services to various industries. Every organization or investors are looking for ways to succeed and thrive in the Tanzanian market; but even the best organizations or investors with the best ideas have no value if they can’t bring their great ideas into life. Kags & Co. has developed an established project management approach to accommodate and manage unique requirements for any project or investment. Additionally, we are also a major stakeholder in the investments, and we play a key role in ensuring their success.",
                "Over 2 years ago, we helped shape the professional project management field. Today, we use those established processes to plan and execute initiatives, with the aim of helping businesses achieve strategic goals and positioning for future success.",
              ]}
            />
            <Image src="images/about-images/abt-us.jpg" objectFit="fill" alt="About Us" borderRadius={15} />
          <AboutSection
            title="Firm History"
            paragraphs={[
              "The firm was founded in 2020 by and Bamwesiga Kabete and Kevin Kageuka. Both are known for expert project management solutions and services to help organizations and their people perform to maximum potential.",
              "Kags & Co. is a values-driven business consulting firm that advises on and executes complex and strategic initiatives, helping organizations overcome challenges and break new ground in their respective industries. Kags & Co. is your “go to” project management and strategy execution partner. We are pass",
              "Kags & Co. began as a project management consulting company that dealt with planning and project management. Business leaders apply project and portfolio management practices that drive performance and operational efficiency.",
            ]}
          />
          <VStack alignItems="start" gap={10}>
            <AboutSection
              title="Our Vision"
              paragraphs={[
                "To be the leading business management consultancy firm in East Africa.",
              ]}
            />
            <AboutSection
              title="Our Values"
              bullets={[
                "Honesty",
                "Integrity",
                "Respect",
                "Responsibility",
                "Excellence",
              ]}
            />
          </VStack>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <AboutSection
              title="Our Mission"
              paragraphs={[
                "To be synonymous with excellence and improve overall project performance through:",
              ]}
              bullets={[
                "Exceptional leadership to transform strategies and solutions to sustainable results",
                "Critically applying sound project management principles in our day-to-day activities",
                "Dedication to uncompromising integrity, continuous improvement, and job security for our greatest asset: our people.",
                "Provision of unmatched quality, caring, and value to our clients.",
                "Exceeding our client’s expectations",
                "Applying new and improved tools and techniques to continuously improve our ability to deliver projects.",
              ]}
            />
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <Image src="images/about-images/serv.jpg" title="Partners" objectFit="cover" borderRadius={15} />
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <AboutSection
              title="Our Clients"
              paragraphs={[
                "The following is a representative list of organizations served by our organization over the years:",
              ]}
              bullets={[
                "Amago Trading Tanzania Limited",
                "JJ Commodity Trading Limited",
                "Mazoka Resources",
                " Lazada Tanzania Limited",
                "Consumer Choice Ltd",
                "Fedha Investment Ltd",
                "KWIECO",
                "GEMO",
                "Co-onaiyasaka Holding Company",
                "Parijat Industries Tanzania",
                "Geo-fields Tanzania",
                "Accountable Africa Limited",
                "Kinondoni Municipal",
                "Apotheker",
                "Unitec Consultancy",
                "Travel Partner",
              ]}
            />
          </GridItem>
        </SimpleGrid>
      </Container>
    </MainLayout>
  );
}

export default AboutPage;
