import { Container, Image, VStack } from "@chakra-ui/react";
import React from "react";
import PageTitle from "../../assets/PageTitle";
import MainLayout from "../../layouts/MainLayout";
import ProjectDetailsSection from "./components/ProjectDetailsSection";
import ProjectSection from "./components/ProjectSection";
import ProjectTableSection from "./components/ProjectTableSection";

function ProjectPage() {
  return (
    <MainLayout>
      <Container maxW="container.xl">
        <PageTitle title="Projects" />
        <VStack gap="150px">
          <ProjectSection
            title="Industries"
            paragraphs={[
              "Our consultants, working individually or as a team, bring a wealth of industry experience, valuable leadership, and problem-solving skills, and apply state of the art project management practices in the following fields:",
            ]}
            bullets={[
              "Mining Industry",
              "Agricultural Industry",
              "Logical Industry",
              "Technology Industry",
            ]}
            bulletCount={2}
          />
          <Image
            src="images/project-images/gold.jpg"
            alt="Gold Processing Plant"
            borderRadius={15}
          />
          <ProjectSection
            title="Our Approach to Projects"
            paragraphs={[
              "Project management requirements vary between organizations and within the same organization over time. We have provided a project management service in several different circumstances.",
              "Start-up businesses may require the establishment of infrastructure or systems to enable the business to commence with operations. In such situations we develop feasibility studies and the required project plan to establish the infrastructure and/or systems. This is followed by the implementation of the project plan, which involves engineering, procurement, construction, commissioning and hand-over to operations.",
              "We continuously aim to improve the delivery of projects through the incorporation of lessons learned and the application of robust systems and process.",
            ]}
            bullets={[
              "Maintain core team of resources",
              "Structure project team around project and client needs",
              "Supplement resources with selected skilled resources",
              "Focus on Project, Engineering and Construction Project Management",
              "Capital, Cost Saving and Business Improvement",
            ]}
            bulletCount={2}
          />

          <ProjectTableSection
            title="Our Projects"
            intro="Our company’s project management experience extends over several project types and industries, of which the most notable are listed below:"
            tableHeads={["PROJECT", "INDUSTRY", "ROLE/TASK"]}
            tableBody={[
              [
                "Buying and Selling Gold at the Geita Gold Centre",
                "Gold Trading",
                "Planning & Implementation",
              ],
              [
                "Buying and Selling Gold at the Kahama Gold Centre Gold Trading Planning & Implementation",
                "Gold Trading",
                "Planning & Implementation",
              ],
              [
                "Preparation of Business Plan and Strategic Plans for a Logistics Company",
                "Logistics",
                "Planning & Feasibility",
              ],
              [
                "Preparation of Business Plan and Strategic Plans for a Wealth Management Company",
                "Financial Services",
                "Planning & Feasibility",
              ],
              [
                "Establishment of a Dairy Farm in Kerege, Pwani",
                "Agriculture",
                "Planning & Implementation",
              ],
              [
                "Establishment of Tailings recovery & beneficiation (Carbon In-Pulp Plant) in Handeni",
                "Mining Process",
                "Planning & Implementation",
              ],
              [
                "Establishment of the desorption of Gold from activated carbon (Elution Plant) in Geita",
                "Mining Process",
                "Planning & Implementation",
              ],
              [
                "Underground mine development in Handeni (5 shafts)",
                "Gold Mining",
                "Planning & Implementation",
              ],
              [
                "Open pit mine developments in Dodoma",
                "Gypsum Mining",
                "Planning & Implementation",
              ],
              [
                "Establishment of a Rice Milling Plant in Ifakara, Morogoro Agriculture Planning & Implementation",
                "Agriculture",
                "Planning & Implementation",
              ],
            ]}
          />
          <ProjectDetailsSection
            title="Gold Trading Projects"
            projects={[
              {
                title: "Kahama Gold Dealer License – 2020",
                body: "We assisted investors to obtain a Dealer License in Kahama. We formed a strategic plan and managed the project from sourcing gold from small-scale miners, brokers and processing plant owners to export of Gold from Tanzania to Hong Kong. This project has been on-going since 2020.",
              },
              {
                title: "Geita Gold Dealer License – 2019",
                body: "We helped investors to obtain a Dealer License in Geita (the biggest gold trading hub in Tanzania). We formed a strategic plan and managed the project from sourcing gold from small-scale miners, brokers, and processing plant owners to export of Gold from Tanzania to Hong Kong. This project has been on-going since 2019.",
              },
            ]}
          />
          <ProjectDetailsSection
            title="Gold Processing Projects"
            projects={[
              {
                title: "Carbon In-Pulp (CIP) Plant – Handeni, Tanga",
                body: "We facilitated investors with the design, construction, installation, and monitoring of the CIP plant. These are some of the steps we took to implement this project.",
                bullets: [
                  "Application of the processing plant license",
                  "Environmental Management Plan",
                  "Chemicals Permit",
                  "Procurement of the Ball Mill and its parts",
                  "Construction of the plant and its amenities",
                  "Installation of the Ball Mill",
                  "Trial run of the processing",
                  "Management of the plant",
                ],
                images: [
                  "images/project-images/gold_proc1.jpg",
                  "images/project-images/gold_proc2.jpg",
                  "images/project-images/gold_proc3.jpg",
                ],
              },
              {
                title: "Elution Plant – Geita Town, Geita",
                body: "We facilitated investors with the design, construction, installation and monitoring of the Elution plant. These are some of the steps we took to implement this project.",
                bullets: [
                  "Application of the processing plant license",
                  "Environmental Management Plan",
                  "Chemicals Permit",
                  "Procurement of the Carbon Columns and electricals",
                  "Installation of the Electrical Transformer",
                  "Construction of the plant and its amenities",
                  "Trial run of the processing",
                  "Management of the plant Management of the plant",
                ],
                images: [
                  "images/project-images/elution_plant1.jpg",
                  "images/project-images/elution_plant2.jpg",
                ],
              },
            ]}
          />
          <ProjectDetailsSection
            title="Small/Medium-Scale Mining Projects"
            projects={[
              {
                title: "Small/Medium Scale Mining – Handeni",
                body: "We facilitated investors with the design, construction, installation, and monitoring of the underground mining shafts. These are some of the steps we took to implement this project.",
                bullets: [
                  "Application of the Primary Mining Licenses",
                  "Locating and Assessing Ore deposits",
                  "Mine method selection (Shaft sinking)",
                  "Shaft design and planning",
                  "Environmental Management Plan",
                  "Application of the Blasting Permit",
                  "Procurement of the mining equipment",
                  "Management of the shafts (on-going)",
                ],
                images: [
                  "images/project-images/small_mining1.jpg",
                  "images/project-images/small_mining2.jpg",
                  "images/project-images/small_mining3.jpg",
                ],
              },
            ]}
          />
          <ProjectDetailsSection
            title="Dairy Farming Project"
            projects={[
              {
                title: "Dairy Farming – Kerege",
                body: "We facilitated investors with the feasibility study, business plan, strategic plan and implementation of the dairy farm. These are some of the steps we took to implement this project.",
                bullets: [
                  "Detailed 5-year Business Plan and Strategic Plan",
                  "Establishment of the company with Kags & Co as Co-owners",
                  "Purchase of the Land",
                  "Construction of the Cow Shed and all amenities",
                  "Sourcing and purchase of quality cows",
                  "Management of the Dairy Farm",
                  "Marketing of the Milk",
                  "Sales of Milk",
                ],
                images: [
                  "images/project-images/dairy1.jpg",
                  "images/project-images/dairy2.jpg",
                ],
              },
            ]}
          />
          <ProjectDetailsSection
            title="Rice Milling Project"
            projects={[
              {
                title: "Rice Milling Plant – Ifakara, Morogoro",
                body: "We are currently working with an investor who wants to establish a Rice Milling Plant in Ifakara, Morogoro. Initially we did a feasibility study together with a Business Plan and Strategic Plan. The investor has already allocated funds for this project, and we are currently under construction of the building facility. The milling machinery has already been purchased.",
              },
            ]}
          />
        </VStack>
      </Container>
    </MainLayout>
  );
}

export default ProjectPage;
