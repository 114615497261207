import { useState } from "react";

export interface MemberInfo {
  name: string;
  title: string;
  image: string;
  bio: string[];
}

function TeamInformation() {
  const [items] = useState<MemberInfo[]>([
    {
      name: "Kevin Kageuka",
      title: "Managing Partner",
      image: "images/team-images/kevin_kageuka.jpg",
      bio: [
        "Kevin Kageuka is a co-founder and Managing Partner of the firm. He is responsible for all aspects of the firm's strategy, team and operations. Kevin leads the firm in its mission to deliver the best client results in the management consulting industry. His main areas of professional experience include credit and risk management, financial research, private and corporate finance, and debt management.",
        "Mr. Kageuka has more than 8 years of financial consulting experience in emerging markets in Eastern and Southern Africa. He has an MBA from the University of Dar es salaam and a post graduate degree in financial analysis and portfolio management from University of Cape Town. His current focus is on Market research and financial advisory within the East African market.",
      ],
    },
    {
      name: "Bamwesiga Kabete",
      title: "Partner",
      image: "images/team-images/bamwesiga_kabete.jpg",
      bio: [
        "Mr. Kabete is a co-founder and in charge of all operations, financial and administrative services. Mr. Kabete has advised management of different companies in structuring and managing portfolios, developing, and implementing strategy, executing turnarounds, improving organizational effectiveness, determining marketing channel and supply chain strategies, designing both performance improvement and growth programs. He has worked across a wide array of industries, including mining industry, agriculture, financial services, and retail. His main areas of professional experience include accounting, financial analysis and evaluation, strategic planning, and project management.",
        "Prior to co-founding the firm, Mr. Kabete was a Financial Analyst at General Cable in Kentucky, US. Mr. Kabete has more than 5 years of financial analysis, accounting, and consulting experience in emerging markets in Eastern Africa.",
        "Over the years he has directed numerous technical assistance projects financed by international organizations, including Amani Minerals (Australia), Orel Resources (Hong Kong) and TT international (Hong Kong). Mr. Kabete has obtained his Bachelor of Arts degree in Accounting and Management at Luther College in Iowa, United States (2016).",
      ],
    },
    // {
    //     name: "Enock Mutasingwa Baisi",
    //     title: "Senior Manager & General Counsel",
    //     image: "images/team-images/enock_baisi.jpg",
    //     bio: [
    //         "Mr. Baisi is responsible for the firm's legal matters, including contractual drafting and negotiation, compliance, and internal policy development. He is an Advocate of the High Court of Tanzania, and all courts subordinate thereto (except primary courts). He holds a Bachelor of Laws degree (LLB) with Honors, at Newcastle University, UK (2013) - Awarded First Class Honours and Master of International Business, at Newcastle University, UK (2014) - Awarded Distinction. He also assists with oversight of project implementation efforts. Additionally, he helps to shape the firm's business development strategy through the building and maintenance of partner relationships, evaluation of new project opportunities, and contributions to proposal development."
    //     ]
    // },
    {
      name: "Happiness Carlos Komba, CPA",
      title: "Financial Analyst",
      image: "images/team-images/happiness_komba.jpg",
      bio: [
        "Ms. Happiness is a financial analyst of the firm, and her focus is on auditing, financial analysis and tax consulting. She has a Bachelor of Commerce in Accounting from the University of Dar es Salaam and holds a CPA(T) from the National Board of Accountants and Auditors (NBAA). A former auditor at TEG Consultancy an auditing firm, Ms Happiness has more than 6 years of Accounting and Auditing Professional in Tanzania.",
      ],
    },
    {
      name: "Eng. Joseph Abraham Augustino",
      title: "Mining Operations & Mining Engineer",
      image: "images/team-images/joseph_augustino.jpg",
      bio: [
        "Mr. Joseph is a Mine Engineer for the firm. Mr. Joseph is a registered Engineer by the Engineers Registration Board Tanzania. Having worked for over 9 years in mining, Engineer Joseph is well versed in all aspects of mining, predominantly in Tanzania and DRC.",
        "Former Mine Engineer at Buckreef Gold and Mazoka Resources, Eng. Joseph has more than 9 years of work experience in the field and is well respected by his peers. Eng. Joseph is very experienced in resource estimation and block modelling, open pits, underground mining, and waste dump designs (using Surpac), drilling and blasting designs in open pits, underground mining, mine production scheduling (or planning with aid of MineSched software), environmental engineering and management programs.",
      ],
    },
    {
      name: "Kenneth Kageuka",
      title: "Computer Engineer",
      image: "images/team-images/kenneth_kageuka.jpg",
      bio: [
        "Mr. Kenneth is the computer specialist, heavily involved with all technological affairs and digital media of the firm. Holding a Bachelors of Science in Computer Engineering from Zhejiang University of Technology.",
        "Kenneth has knowledge in several coding languages and frameworks and has experience in web and mobile based software development and management for companies within and outside the border while meeting the ever-growing standards of the digital market.",
      ],
    },
    {
      name: "Joel Kabete",
      title: "Land and Aerial Surveyor",
      image: "images/team-images/joel_kabete.jpg",
      bio: [
        "Mr. Joel Kabete is a Geomatist,(Land & Aerial Surveyor with GIS analysis), Land developer and property evaluation",
      ],
    },
  ]);

  return items;
}

export default TeamInformation;
