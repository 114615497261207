import { Button, Flex, Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import FormInputFieldItem from "./FormInputFieldItem";
import { useEffect, useRef, useState } from "react";
import { BsFillPersonFill, BsTelephoneFill } from "react-icons/bs";
import { BiMessageAlt } from "react-icons/bi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import emailjs from "@emailjs/browser";

export interface FormItem {
  name: string;
  label: string;
  placeholder: string;
  colSpan: number;
  type: string;
  icon: React.ReactNode;
  isTextArea?: boolean;
}

const API = "/api/index.php";

function EmailContactForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resultText, setResultText] = useState<string | null>(null);
  const form: any = useRef();

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    console.log(values);
    emailjs
      .sendForm(
        "service_qq9v1wh",
        "template_qdjrz88",
        form.current,
        "oC6_f4t0ef-Met1h7"
      )
      .then(
        (result) => {
          setResultText("Successfully Sent");
        },
        (error) => {
          setResultText("Server Error");
        }
      );

    // fetch("/api/index.php", {
    //   method: "POST",
    //   headers: { 'content-type': 'application/json' },
    // }).then(res => {console.log(res); setResultText("Done")})

    //   axios({
    //     method: "POST",
    //     url: `${API}`,
    //     headers: { "content-type": "application/json" },
    //     data: values,
    //   })
    //     .then((result) => {
    //       setResultText("Successfully Sent");
    //       console.log(result.status);
    //     })
    //     .catch((error) => setResultText(error.message));
  };

  useEffect(() => {
    if (resultText !== null) {
      setTimeout(() => setResultText(null), 5000);
      setIsLoading(false);
    }
  }, [resultText]);

  const formItems: FormItem[] = [
    {
      name: "user_name",
      label: "Full Name",
      placeholder: "Full Name",
      colSpan: 2,
      type: "text",
      icon: <BsFillPersonFill />,
    },
    {
      name: "user_phone",
      label: "Phone Number",
      placeholder: "Phone Number",
      colSpan: 1,
      type: "tel",
      icon: <BsTelephoneFill />,
    },
    {
      name: "user_email",
      label: "Email",
      placeholder: "Email",
      colSpan: 1,
      type: "email",
      icon: <MdOutlineAlternateEmail />,
    },
    {
      name: "message",
      label: "Message",
      placeholder: "Message",
      colSpan: 2,
      type: "text-area",
      icon: <BiMessageAlt />,
      isTextArea: true,
    },
  ];

  return resultText ? (
    <Flex
      h="full"
      w="full"
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Heading as="h1" size="md" color="#1C495D" textAlign="center">
        {resultText}
      </Heading>
    </Flex>
  ) : (
    <Formik initialValues={{}} onSubmit={(values) => handleSubmit(values)}>
      <Form ref={form} action={API} method="POST">
        <VStack gap={5} alignItems="start">
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={5} w="full">
            {formItems.map((formItem, i) => (
              <FormInputFieldItem key={i} formItem={formItem} />
            ))}
          </SimpleGrid>
          <Button
            type="submit"
            bgColor="#1C495D"
            color="white"
            w="40%"
            isLoading={isLoading}
            loadingText="Sending"
            isDisabled={isLoading}
            _hover={{ bgColor: "#1C4A5DDA" }}
            _disabled={{ bgColor: "#1C4A5DDA" }}
          >
            Send
          </Button>
        </VStack>
      </Form>
    </Formik>
  );
}

export default EmailContactForm;
