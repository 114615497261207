import { Box, Button, Text, Flex, Heading } from "@chakra-ui/react";
import React from "react";

function ContactBar() {
  return (
    <Flex
      pb="10"
      direction={{ base: "column", md: "row" }}
      justifyContent="space-between"
      alignItems={{ base: "start", md: "center" }}
    >
      <Box mb={{ base: 4, md: 0 }}>
        <Heading as="h1" size="2xl" mb="3">
          Interested?
        </Heading>
        <Text>Take the first step and get in touch with us</Text>
      </Box>
      <Button
        variant="outline"
        _hover={{ color: "#1C4A5D", bgColor: "white" }}
        size="lg"
        as="a"
        href="/contact-us"
        w={{ base: "full", md: "auto" }}
      >
        Get in Touch
      </Button>
    </Flex>
  );
}
export default ContactBar;
