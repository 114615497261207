import {
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ContactItem } from "..";

interface Props {
  contactItem: ContactItem;
}

function ContactComponent(props: Props) {
  const { contactItem } = props;

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      alignItems="center"
      gap={3}
    >
      {contactItem.icon}
      <VStack alignItems={{ base: "center", md: "start" }}>
        <Heading as="h1" size="sm">
          {contactItem.title}
        </Heading>
        <VStack alignItems={{ base: "center", md: "start" }}>
          {contactItem.subtitle.map((item, i) => (
            <Text key={i}>{item}</Text>
          ))}
        </VStack>
      </VStack>
    </Stack>
  );
}

export default ContactComponent;
