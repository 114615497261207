import { BsFillArrowUpSquareFill } from "react-icons/bs";
import React, { useEffect } from "react";
import { Box, Fade, useDisclosure } from "@chakra-ui/react";

function ScrollToTop() {
  const { isOpen, onClose, onOpen } = useDisclosure()

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 400 ? onOpen() : onClose();
    });
  });

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Fade in={isOpen}>
      <Box position="fixed" bottom="40px" right="40px" zIndex="99" color="blue.300" _hover={{opacity: 0.8}}>
        <BsFillArrowUpSquareFill size={50} onClick={goToTop} />
      </Box>
    </Fade>
  );
}

export default ScrollToTop;
