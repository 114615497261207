import { Box, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import {GiCancel} from "react-icons/gi";
import { useLocation } from "react-location";
import { HomeNav } from "./NavigationBar";

interface Props {
  homeNavs: HomeNav[];
  onClose(): void;
}

function NavigationDrawer(props: Props) {
  const { homeNavs, onClose } = props;
  const location = useLocation()

  return (
    <Flex
      p="5"
      h="full"
      justifyContent="space-between"
      alignItems="center"
      direction="column"
    >
      <GiCancel onClick={onClose} size={30} />
      <SimpleGrid columns={1} spacing={10}>
      {
          homeNavs.map((nav: HomeNav, i) => 
          <Box key={i} as="a" textDecoration="none" href={nav.path} mb="15">
            <Text align="center" color={nav.path === location.current.pathname? "blue.300" : "black"}>{nav.text}</Text>
          </Box>)
        }
      </SimpleGrid>
      <Image src="images/base-images/logo.svg" w="50%" />
    </Flex>
  );
}

export default NavigationDrawer;
