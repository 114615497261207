import { Box, Container, Flex, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import NavigationBar from "./components/NavigationBar";
import PageFooter from "./components/PageFooter";
import ScrollToTop from "./components/ScrollToTop";

interface Props {
  children: React.ReactNode;
}

function MainLayout(props: Props) {
  const { children } = props;
  const bgImage = useBreakpointValue({
    base: "images/base-images/background-mob.jpg",
    md: "images/base-images/background.jpg",
  });

  return (
    <Flex
      bgImage={{ md: bgImage }}
      bgColor={{ base: "#F3F3F3", md: "#ffffff" }}
      backgroundSize="cover"
      bgAttachment="fixed"
      bgRepeat="no-repeat"
      width="full"
      minH="100vh"
      direction="column"
      justifyContent="space-between"
      fontSize="16px"
    >
      <Box position="relative">
        <NavigationBar />
        <ScrollToTop />
        <Container maxW="container.xl" mb="100px">
          {children}
        </Container>
      </Box>
      <PageFooter />
    </Flex>
  );
}

export default MainLayout;
