import {
  FormControl,
  FormLabel,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from "@chakra-ui/react";
import { FormItem } from "./EmailContactForm";

interface Props {
  formItem: FormItem;
}

function FormInputFieldItem(props: Props) {
  const { formItem } = props;
  return (
    <GridItem colSpan={{ base: 1, md: formItem.colSpan }}>
      <FormControl w="full">
        <FormLabel htmlFor={formItem.name}>{formItem.label}</FormLabel>
        {formItem.isTextArea ? (
          <Textarea
            id={formItem.name}
            name={formItem.name}
            placeholder={formItem.placeholder}
            autoComplete="off"
          />
        ) : (
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={formItem.icon}
              color="#1C495D"
            />
            <Input
              id={formItem.name}
              required
              autoComplete="off"
              name={formItem.name}
              type={formItem.type}
              placeholder={formItem.placeholder}
            />
          </InputGroup>
        )}
      </FormControl>
    </GridItem>
  );
}

export default FormInputFieldItem;
