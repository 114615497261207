import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { HomePageData } from "../../../layouts/interfaces/HomePage/HomePageData.interface";

interface Props {
  data: HomePageData;
  reverse: boolean;
}

function HomeDisplayItem(props: Props) {
  const { data, reverse } = props;

  return (
    <Container maxW="container.xl">
      <Flex
        borderRadius={15}
        // px={{ base: "10", md: "0" }}
        mb={{base: "100px", md: 0}}
        h="80vh"
        w="full"
        direction="column"
        justifyContent="center"
        // bgColor={reverse ? "#1C4A5DE7" : "transparent"}
      >
        <Flex
          direction={{
            base: "column-reverse",
            md: reverse ? "row-reverse" : "row",
          }}
        >
          <Flex
            direction="column"
            w="100%"
            px={{ base: 0, md: 100 }}
            justifyContent="center"
            alignItems="start"
          >
            <Heading
              mb="5"
              as="h1"
              size={{base: "2xl", md:"4xl"}}
              fontWeight="semibold"
              // color={reverse ? "white" : "#1C495D"}
            >
              {data.title}
            </Heading>
            <Text mb="5" fontSize="lg">
              {data.body}
            </Text>
            <Button
              // variant="bu"
              as="a"
              href={data.href}
              fontWeight="semibold"
              color="white"
              bgColor="#1C495D"
              leftIcon={<AiOutlineArrowRight />}
              _hover={{ bgColor: "#00AEEF", color: "black", transition: "0.5s" }}
            >
              {data.link}
            </Button>
          </Flex>
          <Box w="100%" mb={{ base: 5, md: 0 }}>
            <Image src={data.image} />
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
}

export default HomeDisplayItem;
