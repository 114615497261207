import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Heading,
  Text,
  Box,
} from "@chakra-ui/react";

interface Props {
  title: string;
  intro: string;
  tableHeads: string[];
  tableBody: string[][];
}

function ProjectTableSection(props: Props) {
  const { title, intro, tableHeads, tableBody } = props;
  return (
    <Box w="full">
      <Heading fontWeight="normal" color="#00AEEF" mb={3}>
        {title}
      </Heading>
      <Text mb={10}>{intro}</Text>
      <TableContainer borderTopRadius={15}>
        <Table variant="simple">
          <TableCaption>Projects done by Kags & Co. company</TableCaption>
          <Thead bgColor="#1C495D">
            <Tr>
              <Th color="white">#</Th>
              {tableHeads.map((head, i) => (
                <Th key={i} color="white">{head}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {tableBody.map((row, i) => (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                {row.map((col, j) => (
                  <Td key={(i*3)+j}>{col}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ProjectTableSection;
