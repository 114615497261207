import {Route} from "react-location";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import HomePage from "../pages/HomePage";
import ProjectPage from "../pages/ProjectsPage";
import ServicePage from "../pages/ServicesPage";
import TeamPage from "../pages/TeamPage";

export const routes: Route[] = [
    {
        path: "/",
        element: <HomePage />
    },
    {
        path: "/about",
        element: <AboutPage />
    },
    {
        path: "/services",
        element: <ServicePage />
    },
    {
        path: "/projects",
        element: <ProjectPage />
    },
    {
        path: "/team",
        element: <TeamPage />
    },
    {
        path: "/contact-us",
        element: <ContactPage />
    },
]