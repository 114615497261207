import { Container, SimpleGrid } from "@chakra-ui/react";
import PageTitle from "../../assets/PageTitle";
import TeamInformation from "../../assets/TeamInformation";
import MainLayout from "../../layouts/MainLayout";
import MemberItem from "./components/MemberItem";

function TeamPage() {
  const teamMembers = TeamInformation();
  return (
    <MainLayout>
      <Container maxW="container.xl">
        <PageTitle title="Meet the Team" subtitle="The personnel of Kags & Co. are constituted by high quality
          technocrats with significant professional experience. The company has
          permanent staff of over 5 persons and a large number of external
          collaborators." />
        <SimpleGrid gap="120px">
          {teamMembers.map((m) => (
            <MemberItem member={m} />
          ))}
        </SimpleGrid>
      </Container>
    </MainLayout>
  );
}

export default TeamPage;
