import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { ImCircleRight } from "react-icons/im";

interface Props {
  title: string;
  body: string;
  bullets?: string[];
  titleWeight?: string;
}

function ServiceSection(props: Props) {
  const { title, titleWeight, body, bullets } = props;
  return (
    <Box>
      <Heading
        as="h1"
        fontWeight={titleWeight ?? "thin"}
        color="#00AEEF"
        mb="5"
      >
        {title}
      </Heading>
      <Text mb={bullets && 3}>{body}</Text>
      {bullets && (
        <List>
            <SimpleGrid
          alignItems="start"
          columns={{ base: 1, md: bullets.length > 7 ? 2 : 1 }}
          rowGap={3}
        >
          {bullets.map((bullet, i) => (
            <ListItem key={i}>
              <ListIcon as={ImCircleRight} />
              {bullet}
            </ListItem>
          ))}
        </SimpleGrid>
        </List>
      )}
    </Box>
  );
}

export default ServiceSection;
