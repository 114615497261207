import {
  Box,
  Container,
  Stack,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { BsPhone } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { HiOutlineMail } from "react-icons/hi";
import PageTitle from "../../assets/PageTitle";
import MainLayout from "../../layouts/MainLayout";
import ContactComponent from "./components/ContactComponent";
import EmailContactForm from "./components/EmailContactForm";

export interface ContactItem {
  icon: React.ReactNode;
  title: string;
  subtitle: string[];
}

function ContactPage() {
  const contactItems: ContactItem[] = [
    {
      icon: <BsPhone size={40} />,
      title: "PHONE",
      subtitle: ["+255 745 142 047", "+255 759 330 091"],
    },
    {
      icon: <GrLocation size={40} />,
      title: "ADDRESS",
      subtitle: ["Office No.1, Capital Plaza", "Mbezi Beach, Dar es salaam"],
    },
    {
      icon: <HiOutlineMail size={40} />,
      title: "EMAIL",
      subtitle: ["info@kags.co.tz"],
    },
  ];

  return (
    <MainLayout>
      <Container maxW="container.xl">
        <PageTitle title="Get in Touch" />
        <Stack
          direction={{ base: "column-reverse", md: "row" }}
          spacing="80px"
          w="full"
          mb="100px"
        >
          <Box w={{ base: "full", md: "50%" }}>
            <VStack gap={10} alignItems={{ base: "center", md: "start" }}>
              {contactItems.map((item, i) => (
                <ContactComponent key={i} contactItem={item} />
              ))}
            </VStack>
          </Box>
          <Box w={{ base: "full", md: "50%" }}>
            <EmailContactForm />
          </Box>
        </Stack>
          {/* <Box h="350px">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31698.285451043026!2d39.22215431831187!3d-6.734960488393935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4c33563482ab%3A0xeb50bb7a52c978e7!2sCapital%20Plaza!5e0!3m2!1sen!2stz!4v1655738604212!5m2!1sen!2stz"
            style={{ border: 0, borderRadius: 15 }}
            loading="lazy"
            width="100%"
            height="100%"
            title="Kags & Co Location"
          />
          </Box> */}
      </Container>
    </MainLayout>
  );
}

export default ContactPage;
